import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import documentTitle from '../../utils/documentTitle'
import Layout from '../../layouts'

import PageWrapper from '../../components/PageWrapper'
import ImgComponent from '../../components/Img'
import { TextOnlyContainer } from '../../components/common/Texts'
import ArticleHeader from '../../components/common/ArticleHeader'
import Link from '../../components/common/Link'
import Button from '../../components/Button'

import imgMain from '../../images/articles/the-onlys-main.jpg'
import imgTalentPipeline from '../../images/articles/the-onlys-1.svg'

const Img = styled(ImgComponent)`
  width: 95%;
  margin: 2rem auto;
`

//
//  Main
const WomenInVenture = () => (
  <Layout>
    <Helmet>
      <title>{documentTitle('How we can support the “Onlys”')}</title>
      <meta property="og:image:secure_url" content={imgMain} />
    </Helmet>

    <PageWrapper>
      <ArticleHeader
        date="October 24, 2018"
        title="How we can support the “Onlys”"
        imgSrc={imgMain}
        imgAlt="Lady smiling on laptop"
      />
      <section>
        <TextOnlyContainer>
          <p>
            In October 2018, Lean In and McKinsey & Company released the{' '}
            <Link href="https://womenintheworkplace.com/?fbclid=IwAR0fXPvSBeWmCqmnJjg6O2BVkEzsQbSVllljyJBRaM-5B79U_IqBYc_3fBk#key-findings">
              Women in the Workplace 2018
            </Link>{' '}
            report which found that companies indicate they are highly committed
            to gender diversity but that commitment does not translate into
            meaningful progress.
          </p>

          <p>
            The report states that “Women continue to be vastly underrepresented
            at every level. For women of colour, it’s even worse. Only about one
            in five senior leaders is a woman, and one in twenty-five is a woman
            of colour.”
          </p>
          <Img
            src={imgTalentPipeline}
            alt="Corporate talent pipeline graphic: for men, entry-level positions are at 52%, while the C-suite is at 79%, of the overall talent. For women, the percentage goes from 48% to 21%, respectively."
            caption={
              <Link href="https://www.mckinsey.com/featured-insights/gender-equality/still-looking-for-room-at-the-top-ten-years-of-research-on-women-in-the-workplace">
                Source
              </Link>
            }
          />
          <p>
            Too few women results in too many “Onlys” – women who are the only
            or one of the only women in the room. Being an only is challenging
            and these negative experiences take a toll. We need to work together
            to change this.
          </p>

          <p>
            We have an opportunity to take action, as individuals and as leaders
            within our organizations. We are seeing examples of positive change
            such as new funding models, more initiatives to support women
            entrepreneurs, and programs like Female Funders, focused on
            increasing representation of women who are investing directly and
            those who are influencing investment decisions.
          </p>

          <p>
            In 2015, women held 30% of all wealth controlled by individuals or
            families globally, 44% had grown their wealth independently as
            entrepreneurs. We make 80% of the purchasing decisions. We have the
            expertise, experience and the influence but we have not always had
            the access, especially in the investment sector.
          </p>

          <p>
            Female Funders is committed to changing this. We provide a gateway
            to the knowledge and networks through education, mentorship and
            collaboration. The participants in our program are female corporate
            leaders and innovators who are committed to making meaningful change
            and returns together.
          </p>

          <p>
            When we have diverse perspectives making investment decisions, a
            broader range of companies building unique products and services
            receive capital. The result is a stronger ecosystem of support
            contributing to more sustainable businesses.
          </p>

          <p>
            With more women investing, we don’t have to the be the “Only” and we
            can influence change to incorporate more diversity in leadership
            teams so that those women don’t need to be an “Only”. We need to
            take action now.
          </p>

          <hr />
          <h2>
            Take action to increase diversity in the investment and
            entrepreneurial sectors
          </h2>
          <p>
            Apply for <Link to="/angel-academy">Angel Academy</Link> today to
            join a supportive community of like-minded women and learn to
            evaluate startup companies, to understand the mechanics of venture
            investing, and to build a path to your first angel investment.
          </p>

          <Button href="https://apply.femalefunders.com/">Apply Now</Button>
        </TextOnlyContainer>
      </section>
    </PageWrapper>
  </Layout>
)

export default WomenInVenture
